import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import BoredomGraph from "@/components/graphs/BoredomGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import Footer from "@/components/general/Footer"
import Motif from "@/components/general/Motif"
import pullquote from "@/images/boredom/pullquote.svg"
import Share from "@/components/general/Share"
import openerText from "@/images/boredom/opener-text.png"
import openerTextSml from "@/images/boredom/opener-text-sml.png"

import dci from "@/images/boredom/dci.png"
import dcb from "@/images/boredom/dcb.png"
import shareImg from "@/images/og/og_boredom.jpg"

import SignupPopup from "@/components/general/SignupPopup"


const BoredomPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/boredom"
                title="Free Markets Destroy | Learn How Free Markets Destroy Boredom"
                description="Boredom is a luxury good. For most of human history, we couldn’t afford to be bored. But in the US, the average work week plummeted from 61 hours in 1870 to less than 35 hours today."
                img={shareImg}
            />

            <Opener
                pageName="boredom"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    { min: 1024, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className={"copy--lead"}>
                    <Dropcap img={dcb}>B</Dropcap>oredom is a luxury good. For
                    most of human history, we couldn’t afford to be bored. We
                    were too busy hunting, foraging, fighting, mending—simply
                    surviving. In fact, the English word “boredom” didn’t exist
                    until the 19th century when philosophers like Søren
                    Kierkegaard and Friedrich Nietzsche began to grapple with
                    the existential challenge of filling up the leisure time
                    free markets had created.
                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left", clear: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            display: "none",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 3,
                            display: "block",
                            width: 4,
                            marginBottom: 1,
                            marginTop: 4,
                        },
                    },
                ]}
            >
                <Motif movement={0.1} page="boredom" id="motif_1" />
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 20,
                            marginBottom: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 4,
                            width: 8,
                            marginBottom: 1,
                        },
                    },
                ]}
            >
                <p className={"copy"}>
                    In the United States, the average work week has plummeted
                    from 61 hours in 1870 to less than 35 hours today. According
                    to the CDC, Americans now spend more than 5 hours per day
                    “being entertained, socializing, engaging in exercise and
                    recreation activities, volunteering, taking classes for
                    personal interest, and travelling.”
                </p>
                <p className={"copy"}>
                    Americans now spend almost one-third of our waking hours
                    relaxing. And the reason, in a word, is markets. Not only
                    did free markets make our work more productive, they forced
                    employers to compete. As a result, companies and bosses
                    started offering better pay, shorter days, and, in the
                    modern era, opportunities for time-saving remote work. Since
                    1950, global per capita work has decreased 17%, from 2,227
                    hours a year to 1,855. During that same period,
                    inflation-adjusted income has increased 111%. That means on
                    average we are earning twice as much while working
                    significantly less.
                </p>
                <p className={"copy"}>
                    So the next time you’re bored, blame free markets. Then pick
                    up an iPhone, or a Kindle, or an Xbox.
                </p>
            </Sizing>

            {
                // ======================================= GRAPH =======================================
            }

            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div>

            <BoredomGraph />

            {
                // ======================================= SECTION 2 =======================================
            }

            <ColorChangeBG
                from={{ r: 183, g: 131, b: 185 }}
                to={{ r: 233, g: 146, b: 69 }}
            >
                <Spacing space="10%" />
                <Sizing
                    styles={{ float: "left", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 10,
                                marginBottom: 2,
                            },
                        },
                    ]}
                >
                    <Heading navy={true}>
                        Subjective Value in the Age of Smartphones
                    </Heading>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative", zIndex: 4 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: -3,
                                height: 0,
                                marginBottom: 0,
                                marginTop: 0,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 1,
                                width: 5,
                                marginBottom: 1,
                                marginTop: 1,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: 3,
                                    width: 2,
                                    top: -5,
                                },
                            },
                        ]}
                    >
                        <Motif movement={-0.1} page="boredom" id="motif_3" />
                    </Sizing>
                    <Motif movement={0.1} page="boredom" id="motif_4" />
                </Sizing>

                <Sizing
                    styles={{ float: "left", clear: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 18,
                                marginBottom: 4,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 4,
                                marginBottom: 2,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color={"#68C5B8"} />
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 8,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginRight: 4,
                                width: 8,
                                marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className={"copy--lead copy--navy"}>
                        <Dropcap img={dci}>I</Dropcap>n a free market, there are
                        no sacred cows. In 2004, Blockbuster operated more than
                        9,000 stores, collecting $800 million in revenue from
                        late fees alone. Seven years later, the cash-strapped
                        company announced plans to close all of its remaining
                        stores within a few months. From behemoth to bankrupt in
                        a decade, that is the power of free markets.
                    </div>
                    <p className={"copy"}>
                        To be specific, that’s the power of Netflix, Hulu and
                        YouTube. Competition is the engine that powers progress
                        in markets, and thousands of companies are competing for
                        the right to keep us entertained, informed, and happy.
                        But there’s a catch, something economists call
                        “subjective value.”
                    </p>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -5,
                                    width: 5,
                                    bottom: -1,
                                },
                            },
                        ]}
                    >
                        <Motif movement={-0.1} page="boredom" id="motif_2" />
                    </Sizing>
                </Sizing>
                <Spacing space="0"></Spacing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 0.5,
                                width: 23,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 1,
                                width: 19,
                                paddingBottom: 0.3,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image src="boredom_blockbuster" />
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 17,

                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className="caption">
                        - From 2004 to 2019, Blockbuster went from 9,094 stores
                        to just one.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 8,
                                paddingTop: 2,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        At its core, subjective value is simply the belief that
                        everybody has unique preferences. Subjective value
                        explains why some people love jazz, or poetry, or the
                        movie Die Hard, while others can’t stand them. Products,
                        including TV shows, books and games, don’t have innate
                        value. Their value depends on the subjective preferences
                        of individuals like you. And preferences change over
                        time. That’s why Blockbuster can be worth billions in
                        2004 and pennies just a few years later.
                    </p>
                    <p className={"copy"}>
                        In the past few decades, as technology made it easier
                        for content creators and distributors to find an
                        audience, we’ve witnessed an explosion in the number and
                        variety of products designed to fill our leisure time.
                        With nothing but an iPhone and an internet connection,
                        you can access YouTube, Instagram, or TikTok—the entire
                        internet is at your fingertips. You can connect with
                        friends on Discord or learn French on DuoLingo. You can
                        meditate with Headspace or search for recipes on Tasty.{" "}
                    </p>
                </Sizing>

                <Spacing space="10%"></Spacing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "block",
                                marginLeft: 2,
                                width: 9,
                                paddingBottom: 0.3,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <img style={{ width: "100%" }} src={pullquote} alt="" />`
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: -2,
                                width: 24,
                                height: 22,
                            },
                        },
                        {
                            min: 1024,

                            val: {
                                marginRight: 0,
                                width: 12.5,
                                marginTop: 1,
                                height: 7,
                                marginLeft: 0,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <Image
                        src="boredom_streamer"
                        style={{
                            position: "absolute",
                            width: "100%",
                            left: 0,
                            bottom: 0,
                        }}
                    ></Image>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 8,
                                paddingTop: 2,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        About three-quarters of the increase in leisure time
                        since 2000 has gone to gaming. Games now grab more than
                        7.5 hours of our leisure time every week. What was once
                        a niche industry serving a small but dedicated fanbase
                        has become a $120 billion juggernaut. And thanks to
                        innovations like Twitch, the live-streaming platform
                        acquired by Amazon for $970 million, thousands of gamers
                        have been able to turn their hobby into a source of
                        income as they compete and collaborate with other
                        players from around the world.
                    </p>
                    <p className={"copy"}>
                        In the 19th century, the problem was that we didn’t have
                        anything to do with all of our newfound free time. In
                        2020, the problem is that we have too much to do. In
                        2019, Netflix released a new original title every single
                        day. That’s more new content than the entire TV industry
                        combined produced in 2005.
                    </p>
                    <p className={"copy"}>
                        More, better, cheaper, and something for everyone—that’s
                        the power of markets to improve everything.
                    </p>
                </Sizing>
            </ColorChangeBG>

            <div style={{ backgroundColor: " rgb(233, 146, 69)" }}>
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: -2,
                                width: 24,
                            },
                        },
                    ]}
                >
                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: 20,
                                    maxWidth: "300px",
                                    height: "50px",
                                    marginTop: 2,
                                    paddingBottom: 5,
                                    position: "relative",
                                    display: "block",
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    display: "none",
                                },
                            },
                        ]}
                    >
                        <Share></Share>
                    </Sizing>
                    <Spacing space="0" />
                    <Image
                        src="boredom_hiker"
                        style={{
                            width: "100%",
                        }}
                    />
                </Sizing>
                <Spacing space="0" />
            </div>

            {/* <Spacing space="300vh" /> */}

            <FooterNav next={"racism"} previous={"climate"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default BoredomPage
